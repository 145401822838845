const dataAPI = {
    get_auth_token:'/api/get_auth_token',
  
    get_swipe_list:'/api/home/get_swipe_list',
    get_honors:'/api/home/get_swipe_list',

    get_news:'/api/news',
    get_one_new:'/api/news/<:id>',
    get_newType:'/api/newstype',
    get_reportType:'/api/reporttype',
    get_report:'/api/report',
    get_address:'/api/address',
    set_lang:'/api/sys/set_lang',
    post_contact:'/api/contact',
    get_productLine :'/api/productline',
    get_productLines_info_font:'/api/productline/<:id>',
    get_solution:'/api/solution',
    get_map:'/api/valueset/get_value',

    get_honors:'/api/honor',

    get_quicklink:'/api/quicklink/get_quicklink',

    get_contactData:'/api/quicklink/get_quickcontact',
    get_training:'/api/training',

    get_staff_imgs :'/api/training/get_staff_imgs',

    get_bulk_value :'/api/valueset/get_bulk_value',
}

export default dataAPI


